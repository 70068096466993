/**
 * Shared logic for the cookie consent panel
 *
 * @returns {Object} The methods and values to use
 */
export const useCookieConsent = () => {
	const showPanel = useState('showConsentPanel', () => false);

	const openConsent = () => {
		if (!window.CookieFirst) {
			return;
		}

		showPanel.value = true;
		document.body.classList.add('locked');
	};

	/**
	 * Initialize the CookieFirst script. The CookieFirst script is loaded asynchronously so
	 * we need to wait for it to be loaded before we can use it.
	 *
	 * @param {Function} callback The callback to execute
	 *
	 * @returns {void}
	 */
	const cfInit = (callback) => {
		if (!window) {
			return;
		}

		if (!window.CookieFirst) {
			window.addEventListener('cf_init', () => {
				callback();
			});
		} else {
			callback();
		}
	};

	const cfDestroy = () => {
		window.removeEventListener('cf_init', () => {});
	};

	return {
		openConsent,
		cfInit,
		cfDestroy,
	};
};
